import Payments from "./Payments";

export default class HipayPayment extends Payments {
  get basket() {
    const basket = [];
    const taxRate = this.cart?.Country?.TaxRate || 0;
    this.cart?.Products?.map((item) => {
      let promotionalPrice = item?.Price?.PromotionalPrice;
      let regularPrice = item?.Price?.RegularPrice;
      let price = regularPrice;
      let discount = 0;
      const quantity = item?.Quantity;

      if (promotionalPrice) {
        discount = this.toFixed2(
          (promotionalPrice.PriceTaxIncl - regularPrice.PriceTaxIncl) * quantity
        );

        price = promotionalPrice;
      }

      const newItem = {
        product_reference: item?.Reference + "--" + item?.IdCartDetail,
        name: item?.Description?.Title,
        type: "good",
        quantity: item?.Quantity,
        unit_price: regularPrice?.PriceTaxIncl,
        discount: discount,
        tax_rate: taxRate,
        total_amount: price?.PriceTaxIncl * quantity,
      };
      basket.push(newItem);
    });

    if (this.carrier) {
      const shippingRef = this.carrier.Name + "-" + this.carrier.IdCarrier;
      basket.push({
        product_reference: shippingRef,
        name: this.carrier.Name,
        type: "fee",
        quantity: 1,
        unit_price: this.shipping?.TaxIncl,
        discount: 0,
        tax_rate: taxRate,
        total_amount: this.shipping?.TaxIncl,
      });
    }

    const promoCodeCart = this.cart?.Discounts?.PromoCodes;

    promoCodeCart?.map((promoCode) => {
      if (promoCode) {
        basket.push({
          product_reference: promoCode.IdPromoCode,
          name: promoCode.Title,
          type: "discount",
          quantity: 1,
          unit_price: 0,
          discount: -1 * promoCode?.Reduction?.Value?.TaxIncl,
          tax_rate: "0",
          total_amount: -1 * promoCode?.Reduction?.Value?.TaxIncl,
        });
      }
    });

    const cartRules = this.cart?.Discounts?.CartRules;

    cartRules?.map((cartRule) => {
      if (cartRule) {
        basket.push({
          product_reference: cartRule.IdPromoCode,
          name: cartRule.Title,
          type: "discount",
          quantity: 1,
          unit_price: 0,
          discount: -1 * cartRule?.Reduction?.Value?.TaxIncl,
          tax_rate: "0",
          total_amount: -1 * cartRule?.Reduction?.Value?.TaxIncl,
        });
      }
    });

    // const priceRules = this.cart?.Discounts?.PriceRules;

    // priceRules?.map((priceRule) => {
    //   if (priceRule) {
    //     basket.push({
    //       product_reference: priceRule.IdPriceRule,
    //       name: priceRule.Title,
    //       type: "discount",
    //       quantity: 1,
    //       unit_price: 0,
    //       discount: -1 * priceRule?.Reduction?.Value?.TaxIncl,
    //       tax_rate: "0",
    //       total_amount: -1 * priceRule?.Reduction?.Value?.TaxIncl,
    //     });
    //   }
    // });

    return basket; // price+(price * 0.2)
  }


  async createOrder({ paymentMethod, paymentData }) {
    const browser_info = await this.getBrowserInfo();
    
    const { Id, Firstname, Lastname, Email, Birthday } =
    this.userConnected.Customer;
    const payment_product = paymentData.payment_product;

    const basket = this.basket;

    const currency = this.cart?.Currency?.IsoCode;
    const idorder = this.cart.IdCart;

    let data = {
      cid: Id,
      email: Email,
      eci: "7",
      firstname: Firstname,
      lastname: Lastname,
      description: "basket integration test",
      currency,
      browser_info,
      orderid: idorder,
      shipping: this.shipping?.TaxIncl || 0, //ShippingCostTaxIncl,
      payment_product: payment_product,
      amount: this.cart?.Total?.ToPay?.TaxIncl,
      device_channel: 2,
      custom_data: this.custom_data(paymentMethod),
      ipaddr: "93.26.182.39",
      // issuer_bank_id: "TESTDETT421",
      basket,
    };

    if (paymentData) {
      data.cardtoken = paymentData.token;
    }

    if (
      payment_product === "sofort-uberweisung" ||
      payment_product === "bancontact" ||
      payment_product === "iDEAL" ||
      payment_product === "giropay"
    ) {
      data.accept_url = `${process.env.URL}cart/thanks/accepted/${idorder}`;
      data.decline_url = `${process.env.URL}cart/thanks/decline/${idorder}`;
      data.cancel_url = `${process.env.URL}cart/thanks/cancel/${idorder}`;
      data.exception_url = `${process.env.URL}cart/thanks/exception/${idorder}`;
    }

    const response = await this.http.HIPAY_API.$post("order", data, {
      credentials: true,
      auth: {
        username: process.env.HIPAY_USERNAME_PRIVATE,
        password: process.env.HIPAY_PASSWORD_PRIVATE,
      },
    });

    return {
      response,
      order: {
        amount: this.cart?.Total?.ToPay?.TaxIncl,
        id: idorder,
        currency: currency,
      },
    };
  }
}
